import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../Images/cbclogo.png";
import { FaBars } from "react-icons/fa6";
import { Button } from "antd";
import { BsChatSquareText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { IoFileTrayStackedOutline } from "react-icons/io5";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

export default function Navbarcomp() {
  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            navigate(`/gradeit`);
          }}
        >
          Gradeit - Ultimate Self-Improvement Companion
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            navigate(`/rooster`);
          }}
        >
          Rooster Scheduler - Automatic Timetable Generator
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            navigate(`/skoolit`);
          }}
        >
          Skoolit - Complete School Management Software
        </div>
      ),
    },
  ];
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="main-nav">
        <FaBars
          onClick={handleShow}
          className="barsico"
          style={{ fontSize: "20px" }}
        />
        <div className="nav-logo">
          <img src={Logo} className="image-nav" />
        </div>
        <div className="navitems-container">
          <div
            className="navitem"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </div>

          {/* <div className="navitem">Industry</div> */}
          <div
            className="navitem"
            onClick={() => {
              navigate("/services");
            }}
          >
            Services
          </div>
          <div
            className="navitem"
            onClick={() => {
              navigate("/about");
            }}
          >
            About
          </div>
          <div className="navitem">
            <Dropdown
              menu={{
                items,
              }}
            >
              <div onClick={(e) => e.preventDefault()}>
                Products &nbsp;
                <DownOutlined style={{ fontSize: "10px" }} />
              </div>
            </Dropdown>
          </div>

          <div
            className="navitem"
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact
          </div>
        </div>
        <div className="nav-chat" >
          <MdOutlineAdminPanelSettings
            style={{ fontSize: "20px" }}
            onClick={() => {
              window.open(`http://cbcerp.studibreeze.in/`);
            }}
          />
        </div>
      </div>
      <Offcanvas className="sidebarcanvas" show={show} onHide={handleClose}>
        <Offcanvas.Header className="sidebarcanvasheadder" closeButton>
          <Offcanvas.Title> MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="navbtncontside">
            <div
              className="navbarstylemobile"
              style={{color:'black'}}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </div>
            <div
              onClick={() => {
                navigate("/about");
              }}
              style={{color:'black'}}
              className="navbarstylemobile"
            >
              About
            </div>
            <div
              className="navbarstylemobile"
              onClick={() => {
                navigate("/services");
              }}
              style={{color:'black'}}
            >
              Services
            </div>
            <div className="navbarstylemobile">
              <Dropdown
                menu={{
                  items,
                }}
              >
                <div onClick={(e) => e.preventDefault()}               style={{color:'black'}}>
                  Products &nbsp;
                  <DownOutlined style={{ fontSize: "10px" }} />
                </div>
              </Dropdown>
            </div>
            <div
              className="navbarstylemobile"
              onClick={() => {
                navigate("/contact");
              }}
              style={{color:'black'}}
            >
              Contact
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
