import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Appmedia.css";
import Loader from "./Components/Loader";
import Privacy from "./Pages/Privacy";
import Softwarepage from "./Pages/Softwarepage";

const Home = lazy(() => import("./Pages/Home"));
const Roosterpage = lazy(() => import("./Pages/Roosterpage/Roosterpage"));
const Gradeitpage = lazy(() => import("./Pages/Gradeitpage/Gradeitpage"));
const Schoolitmain = lazy(() => import("./Pages/Schoolitpage/Schoolitmain"));
const About = lazy(() => import("./Pages/About"));
const Contact = lazy(() => import("./Pages/Contact"));
const Services = lazy(() => import("./Pages/Services"));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/about" element={<About />} exact />
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/services" element={<Services />} exact />
          <Route path="/privacy-policy" element={<Privacy />} exact />
          <Route path="/privacypolicy" element={<Privacy />} exact />
          <Route path="/gradeit" element={<Gradeitpage />} exact />
          <Route path="/skoolit" element={<Schoolitmain />} exact />
          <Route path="/rooster" element={<Roosterpage />} exact />
          <Route path="/software" element={<Softwarepage />} exact />
          <Route path="/app-privacy-policy" element={<Privacy />} exact />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
