import React from "react";

export default function Softwarepage() {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        src="https://cbcerp.studibreeze.in/"
        style={{
            width: "100%",
            height: "99.15vh",
            // border: "none",
            // overflow: "hidden",
          }}
        //  frameBorder={0}
      />
    </div>
  );
}
